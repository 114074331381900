/*eslint-disable*/
import {useContext} from 'react';
import {AppContext} from '../context/AppContext';

function useAppContext() {
  const {
    selectedTemplate,
    setSelectedTemplate,
    wishlistCount,
    setWishlistCount
  } = useContext(AppContext);
  return {
    selectedTemplate,
    setSelectedTemplate,
    wishlistCount,
    setWishlistCount
  };
}

export default useAppContext;
