import HTTPClientHandler from "@/services/HTTPClientHandler";

const http = new HTTPClientHandler();

const getUserPreference = async (pageType) => {
  try {
    const response = await http.get({
      url: "/user-preference/me",
      isSecured: true,
    });

    // if (pageType === "guest" && response.data) {
    //   window.location.href = "/";
    // }
    return response.data;
  } catch (error) {
    if (error.request.status === 403 && pageType === "admin") {
      window.location.href = "/auth";
    }
    return error;
  }
};

const updateUserPreference = async (data) => {
  try {
    await http.put({
      url: "/user-preference",
      payload: data,
      isSecured: true,
    });
  } catch (error) {
    return error;
  }
};

export {getUserPreference, updateUserPreference};
