import create from "zustand";
import { getUserPreference } from "@/services/user-preference/userPreference";
import { getTemplateKey } from "@/services/template/template";
import moment from "moment";
import Cookies from "js-cookie";

export const useNavItemsStore = create((set) => ({
  navItems: [],
  userPreferenceData: {},
  showLogin: null,
  showHeader: null,
  userIdKey: {},
  logoUrlRedirect: null,
  isExpired: false,
  daysLeft: null,
  trialDate: null,

  initNavItems: async (pageType, needKey) => {
    const jwtToken = Cookies.get("auth_token");

    let navItems = [];
    let showLogin = null;
    let showHeader = null;
    let logoUrlRedirect = null;
    let isExpired = null;
    let daysLeft = null;
    let trialDate = null;

    const response = jwtToken && (await getUserPreference(pageType));
    const isDekstop = window.innerWidth > 768;

    const isTrailExpiry =
      response?.trialExpiryTime &&
      new Date(response?.trialExpiryTime) < new Date();

    const currentDate = new Date();
    const endDate = new Date(response?.trialExpiryTime); // Replace with your actual end date
    const timeDifference = endDate - currentDate;

    const trailExpiryDate =
      !isTrailExpiry && Math.ceil(timeDifference / (1000 * 3600 * 24));

    // add am pm
    const trailExpiryDateOn = moment(response?.trialExpiryTime).format(
      "MMMM Do YYYY, h:mm a"
    );

    const responseKey =
      (response?.galleryEnabled || needKey) && (await getTemplateKey());
    const commonItems = [
      { title: "Home", url: "/", id: 1 },
      {
        id: 2,
        title: "About Us",
        url: "/about/",
      },
      {
        id: 3,
        title: "Invitation",
        isDropdown: true,
        dropdownData: [
          {
            id: 1,
            title: "Website Invitation",
            url: "/designs/wedding-website-invitation/",
          },
          {
            id: 2,
            title: "PDF Invitation",
            url: "/designs/wedding-pdf-invitation/",
          },
          {
            id: 3,
            title: "Video Invitation",
            url: "/designs/wedding-video-invitation/",
          },
        ],
      },

      { title: "Blog", url: "/blog", id: 4 },
      {
        id: 5,
        title: "Contact Us",
        url: "/contact/",
      },
      ...(isDekstop
        ? [
            {
              id: 6,
              title: "More",
              isDropdown: true,
              dropdownData: [
                {
                  id: 3,
                  title: "Wishlist",
                  url: "/wishlist/",
                },
                {
                  id: 4,
                  title: "Partner Registration",
                  url: "/partner/registration/",
                },
                {
                  id: 5,
                  title: "Shipping and Delivery Policy",
                  url: "/shipping-and-delivery-policy/",
                },
              ],
            },
          ]
        : [
            ...(!jwtToken
              ? [{ id: 6, title: "Login", url: "/auth/" }]
              : [{ id: 6, title: "Profile", url: "/profile" }]),
            ,
            // {
            //   id: 7,
            //   title: "About",
            //   url: "/about/",
            // },
            // {
            //   id: 8,
            //   title: "Contact Us",
            //   url: "/contact/",
            // },
            {
              id: 9,
              title: "Partner Registration",
              url: "/partner/registration/",
            },
            {
              id: 10,
              title: "Shipping and Delivery Policy",
              url: "/shipping-and-delivery-policy/",
            },
          ]),
    ];

    const adminItems = [
      { id: 1, title: "Home", url: "/" },
      { id: 2, title: "Templates", url: "/template/select" },
      { id: 3, title: "Wedding Info", url: "/form" },
      {
        id: 3,
        title: "Gifts",
        url: "/gifts",
      },
      {
        id: 4,
        title: "Website Invitation",
        url: "/designs/wedding-website-invitation/",
      },
      {
        id: 5,
        title: "PDF Invitation",
        url: "/designs/wedding-pdf-invitation/",
      },
      {
        id: 6,
        title: "Video Invitation",
        url: "/designs/video-invitation/",
      },

      response?.budgetPlanningEnabled && {
        id: 7,
        title: "Budget",
        url: "/budget/",
      },
      response?.guestListEnabled && {
        id: 8,
        title: "Guests",
        url: "/guests/",
      },
      response?.galleryEnabled &&
        responseKey && {
          id: 9,
          title: "Gallery",
          url: `/template/${responseKey.userIdKey}/gallery/`,
        },
      {
        id: 10,
        title: "Blog",
        url: "/blog/",
      },
    ];

    switch (pageType) {
      case "admin":
        navItems = [...adminItems];
        showLogin = false;
        logoUrlRedirect = "/";
        isExpired = isTrailExpiry;
        daysLeft = trailExpiryDate;
        trialDate = trailExpiryDateOn;
        break;

      case "blog":
        navItems = response?.id ? [...adminItems] : [...commonItems];
        showLogin = response?.id ? false : true;
        logoUrlRedirect = response?.id ? "/" : "/";
        break;

      case "designs":
        navItems = response?.id ? [...adminItems] : [...commonItems];
        showLogin = response?.id ? false : true;
        logoUrlRedirect = response?.id ? "/" : "/";
        break;

      case "guest":
        navItems = [...commonItems];
        showLogin = response?.id ? false : true;
        logoUrlRedirect = response?.id ? "/" : "/";

        break;

      case "gallery":
        navItems = response?.id ? [...adminItems] : [];
        showHeader = response?.id ? true : false;
        logoUrlRedirect = response?.id ? "/" : "";
        isExpired = isTrailExpiry;
        daysLeft = trailExpiryDate;
        trialDate = trailExpiryDateOn;
        break;

      case "demo":
        navItems = [...commonItems];
        showLogin = true;
        logoUrlRedirect = "/";

        break;

      case "partner":
      default:
        navItems = [{ title: "Home", url: "/", id: 1 }];
        showLogin = true;
        logoUrlRedirect = "/";
    }

    set(() => ({
      navItems,
      showLogin,
      showHeader,
      userPreferenceData: response,
      userIdKey: responseKey,
      logoUrlRedirect,
      isExpired,
      daysLeft,
      trialDate,
    }));
  },
  addNavItem: (navItem) =>
    set((state) => ({
      navItems: [
        ...state.navItems,
        {
          id: navItem.id,
          title: navItem.title,
          url: navItem.url,
        },
      ],
    })),
  updateNavItem: (navItem) =>
    set((state) => ({
      navItems: state.navItems.map((item) => {
        if (item.id === navItem.id) {
          return {
            ...item,
            title: navItem.title,
            url: navItem.url,
          };
        } else {
          return item;
        }
      }),
    })),
  removeNavItem: (id) =>
    set((state) => ({
      navItems: state.navItems.filter((item) => item.id !== id),
    })),

  updateExpired: () => {
    set((state) => ({
      isExpired: state,
    }));
  },
  loginBtnState: (state) => {
    set(() => ({ showLogin: state }));
  },
}));
